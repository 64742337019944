class Lottie {
  constructor() {
    this.animatedElementsContainers =
      document.getElementsByClassName("js-lottie");
    if (this.animatedElementsContainers) {
      if (window.lottieLibraryIsLoaded === false) {
        window.loadJS("/wp-content/themes/s0pho/js/vendor/lottie.js", () => {
          window.lottieLibraryIsLoaded = true;
        });
      }
    }
  }
}

export default Lottie;
