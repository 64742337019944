class Locations {
  constructor() {
    this.moduleContainer = document.querySelector(".locations");
    if (this.moduleContainer) this.init();
  }

  init() {
    let resizeTimer;
    const moduleContainer = this.moduleContainer; /* eslint-disable-line */
    const locationsMap = moduleContainer.querySelector(".locations__map");
    const locationItemsList = Array.from(
      moduleContainer.querySelectorAll(".locations__item")
    );
    const mapMarkerList = Array.from(
      moduleContainer.querySelectorAll(".locations__map__marker")
    );
    const select = moduleContainer.querySelector(".locations__select");
    let currentLocation = "";

    function updateSelect(lastSelectedLocation) {
      const selectOptionsList = Array.from(select.options);
      const idSelectedMapLocation = selectOptionsList.findIndex(
        (selectedOption) => selectedOption.value === lastSelectedLocation
      );
      select.selectedIndex = idSelectedMapLocation;
    }

    function hideLastLocation() {
      if (currentLocation !== "") {
        const currentLocationNode = document.getElementById(currentLocation);
        currentLocationNode.classList.add("u-visually-hidden");

        const closeButton = currentLocationNode.querySelector(
          ".locations__item__close"
        );
        closeButton.removeEventListener(
          "click",
          closeButtonClickHandler /* eslint-disable-line */
        );

        const currentMarker = locationsMap.querySelector(
          `[data-target="${currentLocation}"`
        );
        currentMarker.classList.remove("current-item");
        currentMarker.style.pointerEvents = null;
      }
    }

    function closeButtonClickHandler() {
      select.selectedIndex = 0;
      hideLastLocation();
      currentLocation = "";
    }

    function showCurrentLocation(lastSelectedLocation) {
      const lastLocationNode = document.getElementById(lastSelectedLocation);
      lastLocationNode.classList.remove("u-visually-hidden");

      const closeButton = lastLocationNode.querySelector(
        ".locations__item__close"
      );
      closeButton.addEventListener("click", closeButtonClickHandler);

      const verticalOffset = 4;

      const currentMarker = locationsMap.querySelector(
        `[data-target="${lastSelectedLocation}"`
      );
      /* eslint-disable */
      const currentMarkerLeft = parseInt(currentMarker.style.left);
      const currentMarkerTop = parseInt(currentMarker.style.top);
      /* eslint-enable */
      currentMarker.classList.add("current-item");
      currentMarker.style.pointerEvents = "none";

      lastLocationNode.style.left =
        currentMarkerLeft + "%"; /* eslint-disable-line */
      lastLocationNode.style.top =
        currentMarkerTop + verticalOffset + "%"; /* eslint-disable-line */

      const xlocationNodeInPx =
        (document.documentElement.offsetWidth * currentMarkerLeft) / 100;
      const rightLocationNodeInPx =
        xlocationNodeInPx + lastLocationNode.offsetWidth;
      if (rightLocationNodeInPx >= document.documentElement.offsetWidth) {
        const widthMapContainer = locationsMap.offsetWidth;
        const widthLocation = lastLocationNode.offsetWidth;
        const locationPercentageWidth =
          (widthLocation / widthMapContainer) * 100;
        const leftLocation = currentMarkerLeft - locationPercentageWidth;
        lastLocationNode.style.left =
          leftLocation + "%"; /* eslint-disable-line */
      }
    }

    function updateLocations(lastSelectedLocation) {
      if (lastSelectedLocation === "") {
        select.selectedIndex = 0;
      } else {
        showCurrentLocation(lastSelectedLocation);
        updateSelect(lastSelectedLocation);
      }

      hideLastLocation();

      currentLocation = lastSelectedLocation;
    }

    function selectChangeHandler() {
      const lastSelectedLocation = select.options[select.selectedIndex].value;
      updateLocations(lastSelectedLocation);
    }

    function mapMarkerClickHandler(e) {
      const lastSelectedLocation = e.currentTarget.dataset.target;
      updateLocations(lastSelectedLocation);
    }

    select.addEventListener("change", selectChangeHandler);
    mapMarkerList.forEach((mapMarker) =>
      mapMarker.addEventListener("click", mapMarkerClickHandler)
    );

    function initLocationItems() {
      locationItemsList.forEach((locItem) => {
        locItem.classList.add("u-visually-hidden");
        locItem.style.position = "absolute"; /* eslint-disable-line */
        locationsMap.insertBefore(locItem, null);
      });
    }

    function updateLocationLeft() {
      if (currentLocation) {
        showCurrentLocation(currentLocation);
      }
    }

    function initResizeListener() {
      window.addEventListener("resize", () => {
        clearTimeout(resizeTimer);
        if (document.querySelector(".locations"))
          resizeTimer = setTimeout(updateLocationLeft, 250);
      });
    }

    initLocationItems();
    updateLocations(currentLocation);
    initResizeListener();
  }
}

export default Locations;
