/* eslint-disable no-unused-vars, consistent-return, no-unused-vars, no-restricted-syntax, class-methods-use-this, strict */
import barba from "@barba/core";
import ScrollMagic from "scrollmagic";

class Barba {
  constructor() {
    this.initBarba();
  }

  initBarba() {
    barba.init({
      debug: false,
      cacheIgnore: false,
      prefetchIgnore: false,
      timeout: 5000,
      transitions: [
        {
          name: "default",
          beforeLeave({ current, next, trigger }) {
            document.body.classList.add("u-mobile-block-scroll"); // Block scroll
            document.documentElement.classList.add("barba-is-leaving");
            current.container.classList.remove("barba-container--fadein");
          },
          leave({ current, next, trigger }) {
            const async = this.async();
            setTimeout(() => {
              current.container.classList.toggle("barba-container--fadeout");
              async();
            }, 800);
          },
          beforeEnter({ current, next, trigger }) {
            document.getElementsByTagName("html")[0].style.scrollBehavior =
              "auto";
            current.container.scrollIntoView({
              block: "start",
              behavior: "auto",
            });
          },
          enter({ current, next, trigger }) {
            const async = this.async();
            setTimeout(() => {
              document.documentElement.classList.remove("barba-is-leaving");
              document.getElementsByTagName("html")[0].style.scrollBehavior =
                "smooth";
              next.container.classList.remove("barba-container--fadeout");
              next.container.classList.toggle("barba-container--fadein");
              document.body.classList.remove("u-mobile-block-scroll"); // Remove scroll block
              async();
            }, 200);
          },
        },
      ],
      prevent: ({ el }) => {
        const ignoreClasses = ["ab-item", "another-class-here"]; // Additional (besides .js-barba-prevent) ignore links with these classes (ab-item is for wp admin toolbar links)
        if (/.pdf/.test(el.href.toLowerCase())) {
          return true;
        }
        if (el.classList && el.classList.contains("js-barba-prevent")) {
          return true;
        }
        if (el.dataset && el.dataset.lightbox) {
          return true;
        }
        for (let i = 0; i < ignoreClasses.length; i += 1) {
          if (el.classList.contains(ignoreClasses[i])) {
            return true;
          }
        }
      },
      requestError: (trigger, action, url, response) => {
        console.log("REQUEST ERROR"); // eslint-disable-line no-console
        console.log(url); // eslint-disable-line no-console
        console.log(response); // eslint-disable-line no-console
      },
    });
    // Global enter hook
    barba.hooks.enter(({ current, next, trigger }) => {
      window.requestAnimationFrame(() => {
        this.setBodyClasses(next.container);
        this.setTranslationLinks(next.container);
        this.mobileMenu();
        this.updateCurrentHighlightedMenu(next.html);

        // Modules
        window.modulesLoader.loadModules();

        // Animations
        setTimeout(() => {
          window.setAnimationScenes(new ScrollMagic.Controller());
        }, 300);

        // Link anchor
        this.triggerLinkAnchor();

        // Wysiwyg
        this.fixWysiwyg();

        // Google Analytics
        if (typeof window.ga === "function") {
          window.ga("send", "pageview", window.location.pathname);
        }

        // WP admin bar
        this.fixWpAdminBar();
      });
    });
  }

  setBodyClasses(e) {
    const skipClasses = [""]; // Add the classes you want to skip
    const currentBodyClasses = document.querySelector("body").classList;
    const newBodyClasses = e.querySelector("#body-classes").value;
    const splittedNewBodyClasses = newBodyClasses.split(" ");
    for (let i = 0; i < currentBodyClasses.length; i += 1) {
      if (newBodyClasses.indexOf(currentBodyClasses[i]) > -1) {
        skipClasses.push(currentBodyClasses[i]);
      }
    }
    Array.from(currentBodyClasses).forEach((bodyClass) => {
      // Don't remove class if it exists in skipClasses
      if (!skipClasses.includes(bodyClass)) {
        currentBodyClasses.remove(bodyClass);
      }
    });
    for (let s = 0; s < splittedNewBodyClasses.length; s += 1) {
      document.querySelector("body").classList.add(splittedNewBodyClasses[s]);
    }
  }

  setTranslationLinks(e) {
    "use strict";

    const linksData = e.getAttribute("data-translation-links");
    if (linksData && linksData !== "null") {
      const links = JSON.parse(linksData);
      if (links.constructor === Array && links.length > 0) {
        for (let s = 0; s < links.length; s += 1) {
          const li = document.querySelector(
            `.header__languages ul li:nth-child( ${s + 1}`
          );
          if (li !== null) {
            li.setAttribute("href", links[s]);
          }
        }
      }
    }
  }

  mobileMenu() {
    const mobileMenuToogler = document.getElementById("main-menu-toggler");
    if (mobileMenuToogler) {
      mobileMenuToogler.checked = false;
    }
  }

  /**
   * Parses the DOM structure of the BarbaJS fetch, and applies same classes on the header in DOM.
   * @param {String} htmlString - The new page html in string format from barbaJs
   */
  updateCurrentHighlightedMenu(htmlString) {
    const html = new window.DOMParser().parseFromString(
      htmlString,
      "text/html"
    );
    if ("body" in html === false || html.body.childNodes.length < 1) {
      // Why the length check?
      return;
    }
    // First remove current classes from all menu items and sub menus.
    for (const el of Array.from(document.querySelectorAll(".menu-item"))) {
      el.classList.remove("current-menu-item");
      el.classList.remove("current-menu-parent");
      el.classList.remove("current-menu-ancestor");
    }
    // Get the new current menu items and parents and set the appropiate classes.
    let newEl;
    for (const el of Array.from(html.querySelectorAll(".current-menu-item"))) {
      if (el.id) {
        newEl = document.querySelector(`#${el.id}`);
        if (newEl !== null) {
          newEl.classList.add("current-menu-item");
        }
      }
    }
    for (const el of Array.from(
      html.querySelectorAll(".current-menu-parent")
    )) {
      if (el.id) {
        newEl = document.querySelector(`#${el.id}`);
        if (newEl !== null) {
          newEl.classList.add("current-menu-parent");
        }
      }
    }
    for (const el of Array.from(
      html.querySelectorAll(".current-menu-ancestor")
    )) {
      if (el.id) {
        newEl = document.querySelector(`#${el.id}`);
        if (newEl !== null) {
          newEl.classList.add("current-menu-ancestor");
        }
      }
    }
  }

  triggerLinkAnchor() {
    if (window.location.hash) {
      const anchorId = window.location.hash;
      const anchorIdWithoutHash = anchorId.replace("#", "");
      const elementToScrollTo = document.getElementById(anchorIdWithoutHash);

      // Scroll to the link anchored element.
      elementToScrollTo.scrollIntoView({
        alignToTop: true,
        behavior: "smooth",
      });
    }
  }

  loadWysiwygVideoScripts() {
    if (window.wp) {
      // Remove existing script
      const existingScript = document.querySelector(
        'script[src="/wp-includes/js/mediaelement/mediaelement-and-player.js"]'
      );
      if (existingScript) {
        existingScript.remove();
      }
    }
    window.loadJS(
      "/wp-includes/js/mediaelement/mediaelement-and-player.js",
      () => {
        if (window.wp) {
          // Remove existing script
          const existingScript = document.querySelector(
            'script[src="/wp-includes/js/mediaelement/mediaelement-migrate.js"]'
          );
          if (existingScript) {
            existingScript.remove();
          }
        }
        window.loadJS(
          "/wp-includes/js/mediaelement/mediaelement-migrate.js",
          () => {
            if (window.wp) {
              // Remove existing script
              const existingScript = document.querySelector(
                'script[src="/wp-includes/js/mediaelement/wp-mediaelement.js"]'
              );
              if (existingScript) {
                existingScript.remove();
              }
            }
            window.loadJS(
              "/wp-includes/js/mediaelement/wp-mediaelement.js",
              () => {
                if (window.wp) {
                  // Remove existing script
                  const existingScript = document.querySelector(
                    'script[src="/wp-includes/js/mediaelement/renderers/vimeo.min.js"]'
                  );
                  if (existingScript) {
                    existingScript.remove();
                  }
                }
                window.loadJS(
                  "/wp-includes/js/mediaelement/renderers/vimeo.min.js",
                  () => {}
                );
              }
            );
          }
        );
      }
    );
  }

  fixWysiwyg() {
    const wpVideo = document.querySelectorAll(".wp-video");
    if (wpVideo !== null) {
      if (undefined === window.jQuery) {
        window.loadJS("/wp-includes/js/jquery/jquery.min.js", () => {
          this.loadWysiwygVideoScripts();
        });
      } else {
        this.loadWysiwygVideoScripts();
      }
      if (undefined === window.wp) {
        window.loadCSS(
          "/wp-includes/js/mediaelement/mediaelementplayer-legacy.min.css",
          () => {}
        );
        window.loadCSS(
          "/wp-includes/js/mediaelement/wp-mediaelement.css",
          () => {}
        );
      }
    }
  }

  fixWpAdminBar() {
    if (!document.querySelector("#wpadminbar")) {
      return;
    }

    const xmlhttp = ajaxReq(); // eslint-disable-line
    const url = encodeURI(scriptsAjaxVariables.ajax_url); // eslint-disable-line
    const params = `action=sph_fix_barbajs_wp_admin&location=${window.location.href}`;

    xmlhttp.open("POST", url, true); // set true for async, false for sync request
    xmlhttp.setRequestHeader(
      "Content-type",
      "application/x-www-form-urlencoded"
    );
    xmlhttp.send(params); // or null, if no parameters are passed
    xmlhttp.onreadystatechange = () => {
      if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
        try {
          const response = JSON.parse(xmlhttp.responseText);
          if (response && response.success === true) {
            const adminMenuLinks = Array.from(
              document.querySelector(".ab-top-menu").querySelectorAll("a")
            );
            for (const adminLink of adminMenuLinks) {
              const mregex = /post=(\d+)&/g;
              adminLink.classList.add("js-barba-prevent");
              const href = adminLink.getAttribute("href");
              const groups = mregex.exec(href);
              if (groups && groups.length >= 2) {
                adminLink.setAttribute(
                  "href",
                  href.replace(groups[1], response.pageId)
                );
              } else {
                const chunks = href.split("?url=");
                const encodedWindowLocationHref = encodeURIComponent(
                  window.location.href
                );
                if (chunks.length === 2) {
                  adminLink.setAttribute(
                    "href",
                    `${chunks[0]}?url=${encodedWindowLocationHref}`
                  );
                }
              }
            }
          }
        } catch (error) {
          console.log(error); /* eslint-disable-line */
        }
      }
    };
  }
}

export default Barba;
