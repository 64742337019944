/* eslint-disable class-methods-use-this */

// Import libraries
import LazyLoading from "./lib/lazy-loading";
import Lottie from "./lib/lottie";

// Import components
import Cta from "./template-parts/components/cta";
import Pagination from "./template-parts/components/pagination";

// Import sections
import Locations from "./template-parts/sections/locations";
import Map from "./template-parts/sections/map";
import MosaicPosts from "./template-parts/sections/mosaic-posts";

export default class ModulesLoader {
  constructor(el = document) {
    this.el = el;
  }

  loadModules() {
    // Load libraries
    window.lazyLoading = new LazyLoading();
    window.lottieLibraryIsLoaded = false;
    window.masonryLibraryIsLoaded = false;
    window.lottie = new Lottie();

    // Load components
    window.cta = new Cta();
    window.pagination = new Pagination();

    // Load sections
    window.locations = new Locations();
    window.map = new Map();
    window.mosaicPosts = new MosaicPosts();
  }
}
