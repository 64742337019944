class MosaicPosts {
  constructor() {
    this.mosaicPosts = document.getElementsByClassName("mosaic-posts");

    if (this.mosaicPosts[0]) {
      Array.prototype.forEach.call(this.mosaicPosts, (mosaicPost) => {
        this.gridItems = mosaicPost.querySelectorAll(".mosaic__card--margin");
        this.init();
      });
    }
  }

  init() {
    if (window.masonryLibraryIsLoaded === false) {
      window.loadJS("/wp-content/themes/s0pho/js/vendor/flexmasonry.js", () => {
        window.masonryLibraryIsLoaded = true;
        this.masonryLayout();
      });
    } else {
      this.masonryLayout();
    }
  }

  setContainersHeightAndClasses() {
    window.fm = window.FlexMasonry.init(".masonry-grid", {
      responsive: true,
      breakpointCols: {
        "min-width: 992px": 2,
      },
    });

    this.gridItems.forEach((gridItem) => {
      const height = gridItem.clientHeight;
      gridItem.style.height = `${height}px`; // eslint-disable-line no-param-reassign
    });
  }

  masonryLayout() {
    let reset = null;

    function resetLayout(currentObject) {
      const mosaicGrids = document.querySelectorAll(".mosaic__card--margin");
      if (window.fm) {
        window.fm = null;
      }
      mosaicGrids.forEach((mosaicGrid) => {
        mosaicGrid.style.height = null; // eslint-disable-line no-param-reassign
      });
      currentObject.setContainersHeightAndClasses();
    }

    this.setContainersHeightAndClasses();

    /* Reinitilize the layout when window is resized */
    window.addEventListener("resize", () => {
      clearTimeout(reset);
      reset = setTimeout(resetLayout(this), 100);
    });
  }
}

export default MosaicPosts;
